import FileView from '@weights/FileView'

import { EMPTY_VALUE_PLACEHOLDER } from '@config'

import imageOrFileStringToArray from '@utils/imageOrFileStringToArray'

export default function renderPreviewFile(...params) {
    const h = params[params.length - 1]
    const files = imageOrFileStringToArray(params[0])

    const filesCount = files.length
    if (filesCount === 0) {
        return EMPTY_VALUE_PLACEHOLDER
    }

    const props = {
        props: {
            files
        }
    }
    return h(FileView, props)
}
