<!-- 保证金动用详情 - 动用详情 - 动用信息面板 -->
<template>
    <fragment>
        <information-panel
            title="动用信息"
            :loading="loading"
            :columns="columns"
            :card-bordered="false"
            class="has-margin-top"
            :data-source="utilizationInfo"
        >
            <template v-if="isGovSide && isPorcessDoing" slot="extra">
                <popconfirm-button
                    button-type="text"
                    button-text="取消动用"
                    :loading="cancling"
                    title="确认要执行此操作吗？"
                    @on-confirm="() => handleCancelUtilization(id)"
                />

                <a-button
                    type="primary"
                    style="margin-left: 16px;"
                    @click.stop="handleResultEntry"
                >
                    处理结果录入
                </a-button>
            </template>

            <template #addonAfter>
                <component
                    :is="lowerLevelPanelComponentName"
                    :utilization-info="utilizationInfo"
                />
            </template>
        </information-panel>

        <component
            :is="modalName"
            v-bind="modalProp"
            @on-close="() => handleCloseModal()"
            @on-refresh="() => getUtilizationInfo(id)"
        />
    </fragment>
</template>
<script>
import { Button as AButton } from 'ant-design-vue'
import Fragment from '@components/Fragment'
import PopconfirmButton from '@components/PopconfirmButton'
import InformationPanel from '@components/InformationPanel'

import { SYSTEM_TYPE_GOV_SIDE, UTILIZATION_PROCESS_STATE_DOING } from '@constant/enum'

import { getLowerLevelPanel, generateUpperPanelColumns, LOWER_LEVEL_PANEL_COMPONENTS } from './config'

import cancelUtilizationService from '@service/marginManagement/marginUtilization/cancelUtilizationService'
import getUtilizationInfoService from '@service/marginManagement/marginUtilization/getUtilizationInfoService'

export default {
    name: 'UtilizationInfoPanel',
    components: {
        AButton,
        Fragment,
        PopconfirmButton,
        InformationPanel,
        ...LOWER_LEVEL_PANEL_COMPONENTS,
        ResultEntryModal: () => import('./ResultEntryModal')
    },
    props: {
        id: [Number, String]
    },
    data() {
        return {
            loading: false,
            cancling: false,

            utilizationInfo: {},

            modalProp: {}, // 传入弹框的 `props` 传参
            modalName: undefined // 弹框组件名称
        }
    },
    computed: {
        // 系统类型
        systemType() {
            return this.$route.meta.systemType
        },
        // 是否为 监管端
        isGovSide() {
            return this.systemType === SYSTEM_TYPE_GOV_SIDE
        },
        // 动用方式
        utilizationMethod() {
            return this.utilizationInfo.usageRecordMode
        },
        // 动用处理状态
        utilizationProcessState() {
            return this.utilizationInfo.handleState
        },
        // 该显示的二级面板组件
        lowerLevelPanelComponentName() {
            return getLowerLevelPanel(this.utilizationMethod)
        },
        // 动用处理状态为 处理中
        isPorcessDoing() {
            return this.utilizationProcessState === UTILIZATION_PROCESS_STATE_DOING
        },
        // 附件材料
        bizConfigVOList() {
            return this.utilizationInfo.bizConfigVOList || []
        },
        columns() {
            return Object.freeze(generateUpperPanelColumns(this.bizConfigVOList))
        }
    },
    watch: {
        id: {
            handler(id) {
                this.getUtilizationInfo(id)
            },
            immediate: true
        }
    },
    methods: {
        // 获取动用信息
        async getUtilizationInfo(id) {
            try {
                this.loading = true

                // eslint-disable-next-line
                this.utilizationInfo = await getUtilizationInfoService({ id }) || {}
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        // 取消动用信息
        async handleCancelUtilization(id) {
            try {
                this.cancling = true

                // eslint-disable-next-line
                await cancelUtilizationService({ id })

                this.$message.success('操作成功')
                this.getUtilizationInfo(id)
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.cancling = false
            }
        },
        // 关闭弹框
        handleCloseModal() {
            this.$set(this.modalProp, 'visible', false)
        },
        // 处理结果录入
        handleResultEntry() {
            this.modalName = 'ResultEntryModal'
            this.$set(this.modalProp, 'visible', true)
            this.$set(this.modalProp, 'utilizationInfo', this.utilizationInfo)
        }
    }
}
</script>
