<!-- 保证金动用详情 - 动用详情 - 项目信息面板 -->
<template>
    <project-info-panel :load-data="loadDataFn" :card-bordered="cardBordered" />
</template>
<script>
import ProjectInfoPanel from '@weights/marginManagement/ProjectInfoPanel'

import getProjectInfoService from '@service/project/getProjectInfoService'

export default {
    name: 'MarginPaymentProjectInfoPanel',
    components: {
        ProjectInfoPanel
    },
    props: {
        projectCode: String,
        cardBordered: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        async loadDataFn() {
            // eslint-disable-next-line
            return await getProjectInfoService({ projectCode: this.projectCode })
        }
    }
}
</script>
