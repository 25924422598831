import renderUrlLinkCell from '@/weights/customRender/renderUrlLink'
import renderPreviewFile from '@weights/customRender/renderPreviewFile'

import { empty as emptyFilter } from '@utils'
import { timeFilter, withYuanUnitAmountFilter } from '@utils/filter'

import {
    GUARANTEE_TYPE_BANK_GUARANTEE,
    UTILIZATION_METHOD_CASH_PAYMENT,
    UTILIZATION_METHOD_BANK_GUARANTEE,
    GUARANTEE_TYPE_GUARANTEE_INSURANCE,
    UTILIZATION_METHOD_GUARANTEE_INSURANCE
} from '@constant/enum'

// 保函
const GUARANTEE_PANEL = 'GUARANTEE_PANEL'
// 现金存缴
const CASH_PAYMENT_PANEL = 'CASH_PAYMENT_PANEL'

// 下层面板组件工厂
export const LOWER_LEVEL_PANEL_COMPONENTS = {
    [GUARANTEE_PANEL]: () => import('./GuaranteePanel'),
    [CASH_PAYMENT_PANEL]: () => import('./CashPaymentPanel')
}

// 动用金额项配置
export const UTILIZATION_AMOUNT_COLUMN = {
    dataIndex: 'usageAmount',
    label: '动用金额',
    customRender: val => withYuanUnitAmountFilter(val)
}

// 上层面板（即动用信息面板）字段配置片段一
const UPPER_PANEL_COLUMNS_PART_ONE = [
    {
        dataIndex: 'usageRecordModeText',
        label: '动用方式',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'agent',
        label: '经办人',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'institutionName',
        label: '金融单位',
        customRender: val => emptyFilter(val)
    },
    UTILIZATION_AMOUNT_COLUMN,
    {
        dataIndex: 'usageInitiateDate',
        label: '动用发起日期',
        customRender: val => timeFilter(val)
    },
    {
        dataIndex: 'requireFinishDate',
        label: '要求完成日期',
        customRender: val => timeFilter(val)
    },
    {
        dataIndex: 'handleStateText',
        label: '处理状态',
        customRender: val => emptyFilter(val)
    }
]

// 上层面板（即动用信息面板）字段配置片段二
const UPPER_PANEL_COLUMNS_PART_TWO = [
    {
        dataIndex: 'usageDescription',
        label: '动用说明',
        customRender: val => emptyFilter(val)
    }
]

// 生成最终的上层面板（即动用信息面板）字段配置
export const generateUpperPanelColumns = bizConfigVOList => {
    const fileColumns = []

    bizConfigVOList.forEach((vo, index) => {
        fileColumns.push({
            key: vo.id,
            label: vo.attributeName,
            dataIndex: 'bizConfigVOList',
            customRender: (list, ...params) => {
                const obj = list[index] || {}
                const files = obj.attributeValue

                return renderPreviewFile(files, ...params)
            }
        })
    })

    return [...UPPER_PANEL_COLUMNS_PART_ONE, ...fileColumns, ...UPPER_PANEL_COLUMNS_PART_TWO]
}

// 下层面板主体内容项公共配置
const LOWER_LEVEL_PANEL_COMMON_CONTENT_COLUMNS = [
    UTILIZATION_AMOUNT_COLUMN,
    {
        dataIndex: 'institutionName',
        label: '金融机构',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'actualUsageAmount',
        label: '实际动用金额',
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        dataIndex: 'handleFinishDate',
        label: '实际完成日期',
        customRender: val => timeFilter(val)
    },
    {
        dataIndex: 'payFile',
        label: '支付凭证',
        customRender: renderUrlLinkCell
    },
    {
        dataIndex: 'handleDescription',
        label: '处理说明',
        customRender: val => emptyFilter(val)
    }
]


// 现金存缴面板主体内容项配置
export const CASH_PAYMENT_PANEL_COLUMNS = [
    {
        key: 'accountName',
        dataIndex: 'cashPaymentRecordVO',
        label: '账户名称',
        customRender: vo => emptyFilter(vo?.accountName)
    },
    {
        key: 'accountNumber',
        dataIndex: 'cashPaymentRecordVO',
        label: '账户号码',
        customRender: vo => emptyFilter(vo?.accountNumber)
    },
    ...LOWER_LEVEL_PANEL_COMMON_CONTENT_COLUMNS
]


// 保函面板主体内容项公共配置
const GUARANTEE_PANEL_COMMON_CONTENT_COLUMNS = [
    {
        key: 'guaranteeStartDateAndEndDateText',
        dataIndex: 'guaranteeVO',
        label: '保险期限',
        customRender: vo => emptyFilter(vo?.guaranteeStartDateAndEndDateText)
    },
    ...LOWER_LEVEL_PANEL_COMMON_CONTENT_COLUMNS
]

/**
 * 保函面板主体项配置工厂
 * 通过保函类型进行差异控制
 */
export const GUARANTEE_PANEL_COLUMNS_FACTORY = {
    [GUARANTEE_TYPE_GUARANTEE_INSURANCE]: [
        {
            key: 'policyNo',
            dataIndex: 'guaranteeVO',
            label: '保单号',
            customRender: vo => emptyFilter(vo?.policyNo)
        },
        ...GUARANTEE_PANEL_COMMON_CONTENT_COLUMNS
    ],

    [GUARANTEE_TYPE_BANK_GUARANTEE]: [
        {
            key: 'policyNo',
            dataIndex: 'guaranteeVO',
            label: '保函编号',
            customRender: vo => emptyFilter(vo?.policyNo)
        },
        ...GUARANTEE_PANEL_COMMON_CONTENT_COLUMNS
    ]
}


// 动用方式映射下层面板组件名
const UTILIZATION_METHOD_MAP_LOWER_LEVEL_PANEL_COMPONENT = {
    [UTILIZATION_METHOD_CASH_PAYMENT]: CASH_PAYMENT_PANEL,
    [UTILIZATION_METHOD_BANK_GUARANTEE]: GUARANTEE_PANEL,
    [UTILIZATION_METHOD_GUARANTEE_INSURANCE]: GUARANTEE_PANEL
}

/**
 * 通过动用方式获取下层面板组件
 *
 * @param {number} method 动用方式
 */
export function getLowerLevelPanel(method) {
    return UTILIZATION_METHOD_MAP_LOWER_LEVEL_PANEL_COMPONENT[method]
}
