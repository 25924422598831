import { Button } from 'ant-design-vue'

import { isEmpty, empty as emptyFilter } from '@utils'

export function renderUrlLink(h, url, text = '查看') {
    const props = {
        props: {
            type: 'link'
        },
        attrs: {
            href: url,
            target: '_blank'
        },
        style: {
            padding: 0,
            height: 'auto'
        }
    }
    return h(Button, props, text)
}

export default function renderUrlLinkCell(...params) {
    const url = params[0]
    const text = emptyFilter(url)

    if (isEmpty(url)) {
        return text
    }

    return renderUrlLink(params[params.length - 1], url)
}
