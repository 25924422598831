<!-- 保证金动用详情 - 动用详情 -->
<template>
    <fragment>
        <!-- 项目信息 -->
        <project-info-panel :project-code="projectCode" :card-bordered="false" />

        <!-- 动用信息 -->
        <utilization-info-panel :id="id" />
    </fragment>
</template>
<script>
import Fragment from '@components/Fragment'

import ProjectInfoPanel from './ProjectInfoPanel'
import UtilizationInfoPanel from './UtilizationInfoPanel'

export default {
    name: 'UtilizationDetail',
    components: {
        Fragment,
        ProjectInfoPanel,
        UtilizationInfoPanel
    },
    computed: {
        id() {
            return this.$route.query.id
        },
        projectCode() {
            return this.$route.query.projectCode
        }
    }
}
</script>
